import axios from 'axios';
import { useState, useEffect } from 'react';
import {Constants} from "../Constants";

interface LoginResponse {
    message: string;
    access_token: string;
    refresh_token: string;
    id_token: string;
    expired_at: number;
}

interface CandlestickByExchange {
    buy: number;
    close: number;
    exchange: string;
    high: number;
    low: number;
    num_of_buys: number;
    num_of_sells: number;
    num_of_trades: number;
    open: number;
    sell: number;
    total: number;
}

export interface Candlestick {
    buy: number;
    close: number;
    end_ts: number;
    exchanges: CandlestickByExchange[];
    high: number;
    low: number;
    num_of_buys: number;
    num_of_sells: number;
    num_of_trades: number;
    open: number;
    sell: number;
    start_ts: number;
    symbol: string;
    volume: number;
}


const useHttp = () => {
    const [candlesticks, setCandlesticks] = useState<Candlestick[] | null>(null);
    // const [jwt, setJwt] = useState("JWT-PLACEHOLDER");
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {}, []);

    const login = async (username: string, password: string) => {
        try {
            const response = await axios.post<LoginResponse>(
                Constants.LOGIN_ENDPOINT,
                {'username': username, 'password': password},
                {headers: {'Content-Type': 'application/json'}},
            );
            let jwt = response.data.access_token;
            console.log(jwt);
            localStorage.setItem('jwt', jwt);
            return true;
        } catch (err) {
            localStorage.setItem('jwt', Constants.DEFAULT_JWT);
            errorHandling(err);
            return false;
        }
    };


    const getCandlesticks = async (symbol: string, exchange: string, duration: number, from_ts: number, to_ts: number) => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await axios.post<Candlestick[]>(
                Constants.CANDLESTICKS,
                {
                    symbol,
                    exchange,
                    duration,
                    from_ts,
                    to_ts
                },
                {
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                },
            );
            setCandlesticks(response.data);
        } catch (err) {
            errorHandling(err);
        }
    }

    function errorHandling(err: any) {
        if (axios.isAxiosError(err)) {
            const code = err.response?.status;
            console.log(err.response?.data)
            if (code === 200) {
            } else if (code === 401) {
                setError("Unauthorized");
            } else {
                setError(`Unknown error ${code}`);
            }
        } else {
            setError("Unknown error");
        }
    }

    return {
        login, error,
        getCandlesticks, candlesticks
    };
};

export default useHttp;
