import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Sheet from '@mui/joy/Sheet';

import Layout from './components/Layout';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Cryptocurrencies from './pages/Cryptocurrencies';
import Exchanges from './pages/Exchanges';
import RealtimeData from './pages/RealtimeData';
import Login from './pages/Login';
import TradingPairDetails from './pages/TradingPairDetails';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import {createTheme} from "@mui/material";

const materialTheme = materialExtendTheme();
// const materialTheme = createTheme({
//     Table: {
//         fontFamily: 'monospace',
//     },
// });
export default function App() {
    return (
        // Using Joy UI and Material UI (mui-x-charts) together
        // https://mui.com/joy-ui/integrations/material-ui/#set-up-the-providers
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
            <CssVarsProvider disableTransitionOnChange>
                <CssBaseline />
                <Layout.Root>
                    <Router>
                        <Layout.Header>
                            <Header />
                        </Layout.Header>
                        <Layout.Main>
                            <Sheet sx={{ width: '100%' }}>
                                <Routes>
                                    <Route path="/" element=<Login /> />
                                    <Route path="/login" element=<Login /> />
                                    <Route path="/home" element=<Cryptocurrencies /> />
                                    <Route path="/cryptocurrencies" element=<Cryptocurrencies /> />
                                    <Route path="/exchanges" element=<Exchanges /> />
                                    <Route path="/realtime-data" element=<RealtimeData /> />
                                    <Route path="/cryptocurrencies/:trading_pair" element=<TradingPairDetails /> />
                                </Routes>
                            </Sheet>
                        </Layout.Main>
                    </Router>
                </Layout.Root>
            </CssVarsProvider>
        </MaterialCssVarsProvider>
    );
}
