import {Sheet} from "@mui/joy";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import useHttp, {Candlestick} from "../api/HttpClient";
import {BarPlot} from '@mui/x-charts/BarChart';
import {BarSeriesType} from "@mui/x-charts/models/seriesType/bar";
import Box from "@mui/joy/Box";
import {
    ChartsAxisHighlight,
    ChartsTooltip,
    ChartsXAxis,
    ChartsYAxis, LinePlot, LineSeriesType,
    ResponsiveChartContainer
} from "@mui/x-charts";
import Typography from "@mui/joy/Typography";
import {Constants} from "../Constants";
import useWebSocket from "../api/WebSocketClient";
import {BUY_COLOR, SELL_COLOR, parseScannerWsMessage, SUPPORTED_EXCHANGES, CryptoVolumeData} from "../commons/Commons";

function getSeriesDataFromCandlesticks(candlesticks: Candlestick[]) {
    console.log("called getSeriesDataFromCandlesticks");
    const xLabels = [];
    const seriesData: (BarSeriesType | LineSeriesType)[] = [];
    let setOfExchanges: Map<string, Map<'buy' | 'sell', BarSeriesType>> = new Map();
    let priceData: LineSeriesType = {
        type: 'line',
        data: [],
        label: 'Price',
        id: 'price',
        yAxisKey: 'price_axis',
        color: '#8884d8',
    }
    for (let i = 0; i < candlesticks.length; i++) {
        const candlestick = candlesticks[i];
        priceData.data!.push(candlestick.close);
        for (let j = 0; j < candlestick.exchanges.length; j++) {
            const exchange = candlestick.exchanges[j];
            if (SUPPORTED_EXCHANGES.includes(exchange.exchange)) {
                if (setOfExchanges.has(exchange.exchange)) {
                    setOfExchanges.get(exchange.exchange)!.get('buy')!.data!.push(exchange.buy);
                    setOfExchanges.get(exchange.exchange)!.get('sell')!.data!.push(-exchange.sell);
                } else {
                    setOfExchanges.set(exchange.exchange, new Map([
                        ['buy', {
                            type: 'bar',
                            data: new Array(i).fill(0.0),
                            label: `${exchange.exchange}-buy`,
                            id: `${exchange.exchange}_buy`,
                            stack: 'volume',
                            yAxisKey: 'volume_axis',
                            color: BUY_COLOR,
                        }],
                        ['sell', {
                            type: 'bar',
                            data: new Array(i).fill(0.0),
                            label: `${exchange.exchange}-sell`,
                            id: `${exchange.exchange}_sell`,
                            stack: 'volume',
                            yAxisKey: 'volume_axis',
                            color: SELL_COLOR,
                        }]
                    ]));
                }
            }
        }
        // convert start_ts to human-readable format
        xLabels.push(new Date(candlestick.start_ts * 1000).toLocaleTimeString('en-US', {
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        }));
    }

    seriesData.push(priceData);
    for (let [exchange, data] of setOfExchanges) {
        seriesData.push(data.get('buy')!);
        seriesData.push(data.get('sell')!);
    }
    return {seriesData, xLabels};
}

function LiveBuySellChart() {
    const {trading_pair} = useParams();
    const [scannerEndpoint, setScannerEndpoint] = React.useState(Constants.WS_SCANNER_1M);
    let jwt = localStorage.getItem("jwt")!;
    const {messages} = useWebSocket(scannerEndpoint, jwt)
    const data: CryptoVolumeData[] = parseScannerWsMessage(messages);
    let xLabels: string[] = []
    let seriesData: BarSeriesType[] = [
        {
            type: 'bar',
            data: [],
            label: 'Buy',
            id: 'buy',
            stack: 'volume-buy',
            yAxisKey: 'volume_axis',
            color: BUY_COLOR,
        },
        {
            type: 'bar',
            data: [],
            label: 'Sell',
            id: 'sell',
            stack: 'volume-sell',
            yAxisKey: 'volume_axis',
            color: SELL_COLOR,
        }
    ];
    data.forEach((each) => {
        if (each.symbol === trading_pair) {
            SUPPORTED_EXCHANGES.forEach((supportedExchange) => {
                const exchangeVol = (each.exchanges as any)[supportedExchange]
                xLabels.push(supportedExchange);
                if (exchangeVol) {
                    seriesData[0].data!.push(exchangeVol.buy);
                    seriesData[1].data!.push(exchangeVol.sell);
                } else {
                    seriesData[0].data!.push(0);
                    seriesData[1].data!.push(0);
                }
            });
        }
    });

    return <ResponsiveChartContainer
        margin={{left: 100}}
        height={200}
        width={1000}
        series={seriesData}
        xAxis={[{data: xLabels, scaleType: 'band', disableTicks: false, id: 'x-axis'}]}
        yAxis={[
            {id: 'volume_axis', scaleType: 'linear', min: 0},
        ]}
    >
        <BarPlot skipAnimation borderRadius={2}/>
        <ChartsTooltip trigger={'axis'}/>
        <ChartsAxisHighlight x={'band'} y={'none'}/>
        <ChartsYAxis label="Buy & Sell Vol." position="left" axisId="volume_axis"/>
        <ChartsXAxis label="Exchanges" position="bottom" axisId="x-axis"/>
    </ResponsiveChartContainer>;
}


export default function TradingPairDetails() {
    const {trading_pair} = useParams();
    const {getCandlesticks, candlesticks,} = useHttp();
    useEffect(() => {
        if (trading_pair) {
            const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
            const lookbackPeriod = 3600 * 24;
            const fetchData = async () => {
                await getCandlesticks(trading_pair, "coinbase", 300, currentTimestampInSeconds - lookbackPeriod, currentTimestampInSeconds);
            }
            fetchData();
        } else {
            console.error("No trading pair.")
        }
    }, []);

    if (!candlesticks || candlesticks.length === 0) {
        return <Sheet>Loading Data ...</Sheet>
    }
    const {seriesData, xLabels} = getSeriesDataFromCandlesticks(candlesticks);

    return (
        <Sheet>
            {/* style to avoid overlay of Y label and Y title
                    https://stackoverflow.com/questions/77853618/in-mui-x-charts-how-to-prevent-linechart-y-axis-label-from-overlapping-with-tic
                */}
            <style>
                {`
                    .custom-y-padding-bottom {
                        padding-bottom: 20px; /* Adjust the value as needed */
                    }
                    .custom-y-padding-bottom .MuiChartsAxis-directionY .MuiChartsAxis-label {
                        transform: translateX(-45px) !important;
                    }
                `}
            </style>
            <Typography variant="soft" component="h1" color={"primary"} gutterBottom>
                {trading_pair}
            </Typography>

            <Typography component="h2" gutterBottom>
                Live Buy vs Sell Volume on Major Exchanges
            </Typography>
            <Sheet className="custom-y-padding-bottom"
                variant="outlined"
                sx={{width: '100%', boxShadow: 'sm', borderRadius: 'sm'}}
            >
                <LiveBuySellChart/>
            </Sheet>

            <Typography component="h2" gutterBottom>
                Historical Buy vs Sell Volume and Price
            </Typography>

            <Sheet
                variant="outlined"
                sx={{width: '100%', boxShadow: 'sm', borderRadius: 'sm'}}
            >
                <Box className="custom-y-padding-bottom">
                    <ResponsiveChartContainer
                        margin={{left: 100}}
                        height={500}
                        series={seriesData}
                        xAxis={[{data: xLabels, scaleType: 'band', disableTicks: true, id: 'x-axis'}]}
                        yAxis={[
                            {id: 'volume_axis', scaleType: 'linear'},
                            {id: 'price_axis', scaleType: 'linear'},
                        ]}
                    >
                        <BarPlot skipAnimation borderRadius={2}/>
                        <LinePlot></LinePlot>
                        <ChartsTooltip trigger={'axis'}/>
                        <ChartsAxisHighlight x={'line'} y={'none'}/>
                        <ChartsYAxis label="Buy/Sell Vol." position="right" axisId="volume_axis"/>
                        <ChartsYAxis label="Price" position="left" axisId="price_axis"/>
                        <ChartsXAxis label="Last 24 Hours" position="bottom" axisId="x-axis"/>
                    </ResponsiveChartContainer>
                </Box>
            </Sheet>
        </Sheet>
    );
}