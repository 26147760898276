import React from "react";
import List from '@mui/joy/List';

export default function Cryptocurrencies() {
    return <List
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: 2,
        }}
    >
        Exchanges
    </List>;
}