import * as React from 'react';
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import TeamNav from './Navigation';
import {useLocation} from "react-router-dom";
import {Person, SupervisedUserCircle, SupervisedUserCircleOutlined, VerifiedUser} from "@mui/icons-material";

function ColorSchemeToggle() {
    const { mode, setMode: setMaterialMode } = useMaterialColorScheme();
    const { setMode: setJoyMode } = useJoyColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
        setMaterialMode('dark');
        setJoyMode('dark');
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary"/>;
    }
    return (
        <Tooltip title="Change theme" variant="outlined">
            <IconButton
                id="toggle-mode"
                size="sm"
                variant="plain"
                color="neutral"
                sx={{alignSelf: 'center'}}
                onClick={() => {
                    if (mode === 'light') {
                        setMaterialMode('dark');
                        setJoyMode('dark');
                    } else {
                        setMaterialMode('light');
                        setJoyMode('light');
                    }
                }}
            >
                {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
            </IconButton>
        </Tooltip>
    );
}

export default function Header() {
    const pathname = useLocation().pathname;
    const isHidden = (pathname === '/' || pathname === '/login' || pathname === '/sign-up');
    if (isHidden) {
        return null;
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
            }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <IconButton
                    size="md"
                    variant="outlined"
                    color="neutral"
                    sx={{
                        display: {xs: 'none', sm: 'inline-flex'},
                        borderRadius: '50%',
                    }}
                >
                    <Person/>
                </IconButton>
                <Typography level="title-sm" textColor="text.icon">
                    {localStorage.getItem('username')}
                </Typography>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/#/home"
                    size="lg"
                    sx={{alignSelf: 'center'}}
                >
                    Home
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/#/cryptocurrencies"
                    size="lg"
                    sx={{alignSelf: 'center'}}
                >
                    Cryptocurrencies
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/#/exchanges"
                    size="lg"
                    sx={{alignSelf: 'center'}}
                >
                    Exchanges
                </Button>
                <Button
                    variant="plain"
                    color="neutral"
                    component="a"
                    href="/#/realtime-data"
                    size="lg"
                    sx={{alignSelf: 'center'}}
                >
                    Real-time Data
                </Button>
            </Stack>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >
                <Input
                    size="md"
                    variant="outlined"
                    placeholder="Search for coins & exchanges"
                    startDecorator={<SearchRoundedIcon color="primary"/>}
                    endDecorator={
                        <IconButton
                            variant="outlined"
                            color="neutral"
                            sx={{bgcolor: 'background.level1'}}
                        >
                            <Typography level="title-sm" textColor="text.icon">
                                ⌘ K
                            </Typography>
                        </IconButton>
                    }
                    sx={{
                        alignSelf: 'center',
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'flex',
                        },
                        width: '350px',
                    }}
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={{display: {xs: 'inline-flex', sm: 'none'}, alignSelf: 'center'}}
                >
                    <SearchRoundedIcon/>
                </IconButton>
                <ColorSchemeToggle/>
                {/*<Dropdown>*/}
                {/*  <MenuButton*/}
                {/*    variant="plain"*/}
                {/*    size="sm"*/}
                {/*    sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}*/}
                {/*  >*/}
                {/*    <Avatar*/}
                {/*      src="https://i.pravatar.cc/40?img=2"*/}
                {/*      srcSet="https://i.pravatar.cc/80?img=2"*/}
                {/*      sx={{ maxWidth: '32px', maxHeight: '32px' }}*/}
                {/*    />*/}
                {/*  </MenuButton>*/}
                {/*  <Menu*/}
                {/*    placement="bottom-end"*/}
                {/*    size="sm"*/}
                {/*    sx={{*/}
                {/*      zIndex: '99999',*/}
                {/*      p: 1,*/}
                {/*      gap: 1,*/}
                {/*      '--ListItem-radius': 'var(--joy-radius-sm)',*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <MenuItem>*/}
                {/*      <Box*/}
                {/*        sx={{*/}
                {/*          display: 'flex',*/}
                {/*          alignItems: 'center',*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        <Avatar*/}
                {/*          src="https://i.pravatar.cc/40?img=2"*/}
                {/*          srcSet="https://i.pravatar.cc/80?img=2"*/}
                {/*          sx={{ borderRadius: '50%' }}*/}
                {/*        />*/}
                {/*        <Box sx={{ ml: 1.5 }}>*/}
                {/*          <Typography level="title-sm" textColor="text.primary">*/}
                {/*            Rick Sanchez*/}
                {/*          </Typography>*/}
                {/*          <Typography level="body-xs" textColor="text.tertiary">*/}
                {/*            rick@email.com*/}
                {/*          </Typography>*/}
                {/*        </Box>*/}
                {/*      </Box>*/}
                {/*    </MenuItem>*/}
                {/*    <ListDivider />*/}
                {/*    <MenuItem>*/}
                {/*      <HelpRoundedIcon />*/}
                {/*      Help*/}
                {/*    </MenuItem>*/}
                {/*    <MenuItem>*/}
                {/*      <SettingsRoundedIcon />*/}
                {/*      Settings*/}
                {/*    </MenuItem>*/}
                {/*    <ListDivider />*/}
                {/*    <MenuItem component="a" href="/blog/first-look-at-joy/">*/}
                {/*      First look at Joy UI*/}
                {/*      <OpenInNewRoundedIcon />*/}
                {/*    </MenuItem>*/}
                {/*    <MenuItem*/}
                {/*      component="a"*/}
                {/*      href="https://github.com/mui/material-ui/tree/master/docs/data/joy/getting-started/templates/email"*/}
                {/*    >*/}
                {/*      Sourcecode*/}
                {/*      <OpenInNewRoundedIcon />*/}
                {/*    </MenuItem>*/}
                {/*    <ListDivider />*/}
                {/*    <MenuItem>*/}
                {/*      <LogoutRoundedIcon />*/}
                {/*      Log out*/}
                {/*    </MenuItem>*/}
                {/*  </Menu>*/}
                {/*</Dropdown>*/}
            </Box>
        </Box>
    );
}
