import { useEffect, useState, useRef } from 'react';

class WebSocketClient {
    private socket: WebSocket;
    private url: string;

    constructor(url: string, jwt: string = '') {
        this.url = url;
        this.socket = new WebSocket(this.url);
        this.socket.onopen = () => {
            this.socket.send(JSON.stringify({jwt}));
        }
    }

    onMessage(callback: (data: any) => void) {
        this.socket.onmessage = (event) => {
            callback(JSON.parse(event.data));
        }
    }

    send(data: any) {
        this.socket.send(JSON.stringify(data));
    }

    close() {
        this.socket.close();
    }
}

const useWebSocket = (url: string, jwt: string, maxMsgQueueSize=1) => {
    const [messages, setMessages] = useState<any[]>([]);
    const socketClient = useRef<WebSocketClient | null>(null);

    useEffect(() => {
        socketClient.current = new WebSocketClient(url, jwt);

        socketClient.current.onMessage((message) => {
            setMessages((prevMessages) => [...prevMessages, message].slice(-maxMsgQueueSize));
        });

        return () => {
            socketClient.current?.close();
        };
    }, [url]);

    const sendMessage = (message: any) => {
        socketClient.current?.send(message);
    };

    return { messages, sendMessage };
};

export default useWebSocket;