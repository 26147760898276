interface VolumeMetrics {
    buy: number;
    sell: number;
    total: number;
    num_of_buys: number;
    num_of_sells: number;
    num_of_trades: number;
}

interface ExchangeMetrics extends VolumeMetrics {
    last_price: number;
}

export interface CryptoVolumeData {
    symbol: string;
    buy: number;
    sell: number;
    buy_ratio: number;
    sell_ratio: number;
    num_of_buys: number;
    num_of_sells: number;
    num_of_trades: number
    last_price: number;
    exchanges: object; // Map<string, ExchangeMetrics>;
}

export function parseScannerWsMessage(messages: any[]): CryptoVolumeData[] {
    return messages.map(
        eachMessage => {
            return JSON.parse(eachMessage.data).map((each: any) => {
                const total = each.buy + each.sell + 1e-9;
                each.buy_ratio = each.buy / total * 100;
                each.sell_ratio = each.sell / total * 100;
                return each;
            });
        }
    )[0] ?? []
}

export const BUY_COLOR = '#198754';
export const SELL_COLOR = '#dc3545';

export const SUPPORTED_EXCHANGES = [
    'binance', 'binanceus', 'bitmex', 'coinbase', 'hitbitc', 'huobi', 'kraken', 'bitstamp', 'bitflyer', 'gemini', 'upbit'
];
