import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import useHttp from "../api/HttpClient";
import {useEffect} from "react";

export default function Login() {
    const {login} = useHttp();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [message, setMessage] = React.useState('');

    useEffect(() => {
        let usernameInCache = localStorage.getItem("username");
        if (usernameInCache) {
            setUsername(usernameInCache);
        }
    }, []);

    async function onLoginClicked() {
        let success = await login(username, password);
        if (success) {
            window.location.href = '/#/home';
            localStorage.setItem('username', username);
            setMessage('')
        } else {
            localStorage.setItem('username', '');
            setMessage('Login failed. Please check your username and password.');
        }
    }

    return (
        <Sheet sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Sheet
                sx={{
                    width: 400,
                    mx: 'auto', // margin left & right
                    my: 4, // margin top & bottom
                    py: 3, // padding top & bottom
                    px: 2, // padding left & right
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'md',
                }}
                variant="outlined"
            >
                <div>
                    <Typography level="h4" component="h1">
                        <b>Welcome to Parana Platforms!</b>
                    </Typography>
                    <Typography level="body-sm">Sign in to continue.</Typography>
                </div>
                <FormControl>
                    <FormLabel>Username</FormLabel>
                    <Input
                        // html input attribute
                        name="Username"
                        type="string"
                        placeholder="your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                        // html input attribute
                        name="password"
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>
                <Button sx={{mt: 1 /* margin top */}} onClick={onLoginClicked}>Log in</Button>
                <Typography
                    endDecorator={<Link href="/#/sign-up">Sign up for free</Link>}
                    level="title-md"
                    sx={{alignSelf: 'center'}}
                >
                    Don&apos;t have an account?
                </Typography>
                {
                    message &&
                    <Typography textAlign="center" level="title-md" color="danger">
                        {message}
                    </Typography>
                }
            </Sheet>
        </Sheet>
    );
}