export abstract class Constants {
    static readonly DEFAULT_JWT = 'JWT-PLACEHOLDER';

    // For local development
    // static readonly HTTP_API_BASE_URL: string = 'http://localhost:5000';
    static readonly HTTP_API_BASE_URL: string = 'https://paranaplatforms.com:5000';
    static readonly LOGIN_ENDPOINT: string = `${Constants.HTTP_API_BASE_URL}/login`;
    static readonly CANDLESTICKS: string = `${Constants.HTTP_API_BASE_URL}/candlesticks`;
    static readonly CANDLESTICK_ENDPOINT: string = `${Constants.HTTP_API_BASE_URL}/candlestick_symbols`;

    static readonly WS_SCANNER_BASE_URL: string = 'wss://paranaplatforms.com:8763';
    static readonly WS_SCANNER_1M: string = `${Constants.WS_SCANNER_BASE_URL}/1m`;
    static readonly WS_SCANNER_5M: string = `${Constants.WS_SCANNER_BASE_URL}/5m`;
    static readonly WS_SCANNER_15M: string = `${Constants.WS_SCANNER_BASE_URL}/15m`;
    static readonly WS_SCANNER_1H: string = `${Constants.WS_SCANNER_BASE_URL}/1h`;
    static readonly WS_SCANNER_6H: string = `${Constants.WS_SCANNER_BASE_URL}/6h`;
    static readonly WS_SCANNER_24H: string = `${Constants.WS_SCANNER_BASE_URL}/24h`;

    static readonly WS_TRADES_URL: string = 'wss://paranaplatforms.com:8765';

    // Prevent instantiation of this class
    private constructor() {}
}
