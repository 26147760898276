import * as React from 'react';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Tooltip from '@mui/joy/Tooltip';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {visuallyHidden} from '@mui/utils';
import {useEffect, useState} from "react";
import {Constants} from "../Constants";
import useHttp from "../api/HttpClient";
import useWebSocket from "../api/WebSocketClient";
import {ButtonGroup} from "@mui/joy";
import Button from "@mui/joy/Button";
import {BarChart} from '@mui/x-charts/BarChart';
import {LineChart} from "@mui/x-charts";
import { useNavigate } from 'react-router-dom';
import {BUY_COLOR, CryptoVolumeData, parseScannerWsMessage, SELL_COLOR} from "../commons/Commons";
import {MakeOptional} from "@mui/x-charts/internals";



function labelDisplayedRows({
                                from,
                                to,
                                count,
                            }: {
    from: number;
    to: number;
    count: number;
}) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Omit<CryptoVolumeData, 'exchanges'>;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'symbol',
        numeric: false,
        disablePadding: true,
        label: 'Pair',
    },
    {
        id: 'last_price',
        numeric: false,
        disablePadding: true,
        label: 'Price',
    },
    {
        id: 'buy',
        numeric: true,
        disablePadding: false,
        label: 'Buy Vol. (USD)',
    },
    {
        id: 'sell_ratio',
        numeric: true,
        disablePadding: false,
        label: 'Buy v.s. Sell',
    },
    {
        id: 'sell',
        numeric: true,
        disablePadding: false,
        label: 'Sell Vol. (USD)',
    },
    {
        id: 'num_of_trades',
        numeric: true,
        disablePadding: false,
        label: 'Number of Trades',
    },
];

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Omit<CryptoVolumeData, 'exchanges'>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {order, orderBy, rowCount, onRequestSort} =
        props;
    const createSortHandler =
        (property: keyof Omit<CryptoVolumeData, 'exchanges'>) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <thead>
        <tr>
            <th>
                #
            </th>
            {headCells.map((headCell) => {
                const active = orderBy === headCell.id;
                return (
                    <th
                        key={headCell.id}
                        aria-sort={
                            active
                                ? ({asc: 'ascending', desc: 'descending'} as const)[order]
                                : undefined
                        }
                    >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                            underline="none"
                            color="neutral"
                            textColor={active ? 'primary.plainColor' : undefined}
                            component="button"
                            onClick={createSortHandler(headCell.id)}
                            fontWeight="lg"
                            startDecorator={
                                headCell.numeric ? (
                                    <ArrowDownwardIcon sx={{opacity: active ? 1 : 0}}/>
                                ) : null
                            }
                            endDecorator={
                                !headCell.numeric ? (
                                    <ArrowDownwardIcon sx={{opacity: active ? 1 : 0}}/>
                                ) : null
                            }
                            sx={{
                                '& svg': {
                                    transition: '0.2s',
                                    transform:
                                        active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                },
                                '&:hover': {'& svg': {opacity: 1}},
                            }}
                        >
                            {headCell.label}
                            {active ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </Link>
                    </th>
                );
            })}
        </tr>
        </thead>
    );
}

interface EnhancedTableToolbarProps {
    scannerEndpoint: string;
    setScannerEndpoint: (value: string) => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const scannerEndpointConfig = [
        {'label': '1min', 'endpoint': Constants.WS_SCANNER_1M},
        {'label': '5min', 'endpoint': Constants.WS_SCANNER_5M},
        {'label': '15min', 'endpoint': Constants.WS_SCANNER_15M},
        {'label': '1h', 'endpoint': Constants.WS_SCANNER_1H},
        {'label': '6h', 'endpoint': Constants.WS_SCANNER_6H},
        {'label': '24h', 'endpoint': Constants.WS_SCANNER_24H},
    ];


    const {setScannerEndpoint, scannerEndpoint} = props;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                py: 1,
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                borderTopLeftRadius: 'var(--unstable_actionRadius)',
                borderTopRightRadius: 'var(--unstable_actionRadius)',
            }}
        >
            <Typography
                level="body-lg"
                sx={{flex: '1 1 100%'}}
                id="tableTitle"
                component="div"
            >
                Cryptocurrencies
            </Typography>
            <ButtonGroup variant="outlined" size="sm" color="primary" aria-label="primary button group">
                {
                    scannerEndpointConfig.map((eachConfig, index) => {
                        return <Button
                            key={index}
                            variant={scannerEndpoint === eachConfig.endpoint ? 'solid' : 'outlined'}
                            onClick={() => setScannerEndpoint(eachConfig.endpoint)}>{eachConfig.label}
                        </Button>
                    })
                }
            </ButtonGroup>

            <Tooltip title="Filter list">
                <IconButton size="sm" variant="outlined" color="neutral">
                    <FilterListIcon/>
                </IconButton>
            </Tooltip>
        </Box>
    );
}

function TableSortAndSelection() {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Omit<CryptoVolumeData, 'exchanges'>>('symbol');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [scannerEndpoint, setScannerEndpoint] = React.useState(Constants.WS_SCANNER_1M);
    const navigate = useNavigate();

    let jwt = localStorage.getItem("jwt")!;
    const {messages} = useWebSocket(scannerEndpoint, jwt)

    let rows: CryptoVolumeData[] = parseScannerWsMessage(messages);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Omit<CryptoVolumeData, 'exchanges'>,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        navigate(`/cryptocurrencies/${encodeURIComponent(name)}`);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
        setRowsPerPage(parseInt(newValue!.toString(), 10));
        setPage(0);
    };

    const getLabelDisplayedRowsTo = () => {
        if (rows.length === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? rows.length
            : Math.min(rows.length, (page + 1) * rowsPerPage);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Sheet
            variant="outlined"
            sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
        >

            <EnhancedTableToolbar scannerEndpoint={scannerEndpoint} setScannerEndpoint={setScannerEndpoint}/>
            <Table
                aria-labelledby="tableTitle"
                hoverRow
                sx={{
                    fontFamily: 'monospace',
                    '--TableCell-headBackground': 'transparent',
                    '--TableCell-selectedBackground': (theme) =>
                        theme.vars.palette.success.softBg,
                    '& thead th:nth-child(1)': {
                        width: '40px',
                    },
                    '& thead th:nth-child(2)': {
                        width: '15%',
                    },
                    '& thead th:nth-child(3)': {
                        width: '10%',
                    },
                    '& thead th:nth-child(4)': {
                        width: '15%',
                    },
                    '& thead th:nth-child(5)': {
                        width: '30%',
                    },
                    '& thead th:nth-child(6)': {
                        width: '15%',
                    },
                    '& thead th:nth-child(7)': {
                        width: '15%',
                    },
                    //
                    '& tr > *:nth-child(4)': {textAlign: 'right'},
                    '& tr > *:nth-child(5)': {textAlign: 'center'},
                    '& tr > *:nth-child(6)': {textAlign: 'left'},
                    '& tr > *:nth-child(7)': {textAlign: 'right'},
                }}
            >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                />
                <tbody>
                {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {

                        const labelId = `enhanced-table-checkbox-${index}`;
                        const total = row.buy + row.sell + 1e-9;
                        const buy_ratio = row.buy / total * 100;
                        const sell_ratio = row.sell / total * 100;
                        return (
                            <tr
                                onClick={(event) => handleClick(event, row.symbol)}
                                tabIndex={-1}
                                key={row.symbol}
                            >
                                <th scope="row">
                                    {index + 1}
                                </th>
                                <th id={labelId} scope="row">
                                    <Typography color={"primary"}>
                                        {row.symbol}
                                    </Typography>
                                </th>
                                <td>{Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                }).format(row.last_price)}</td>
                                <td>{Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                }).format(row.buy)}</td>
                                <td>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center', // Adjust this to control spacing between boxes
                                            alignItems: 'center', // Adjust this to control vertical alignment
                                        }}
                                    >
                                        <Typography pr={2} sx={{
                                            fontFamily: 'monospace',
                                            color: BUY_COLOR
                                        }}> {buy_ratio.toFixed(1) + '%'} </Typography>
                                        <Box
                                            sx={{
                                                borderTopRightRadius: '3px',
                                                borderBottomRightRadius: '3px',
                                                borderTopLeftRadius: '5px',
                                                borderBottomLeftRadius: '5px',
                                                width: `${buy_ratio}px`,
                                                height: '20px', // Set your desired height
                                                backgroundColor: BUY_COLOR, // Set your desired background color
                                            }}
                                        />
                                        <Box
                                            ml={'2px'}
                                            sx={{
                                                borderTopRightRadius: '5px',
                                                borderBottomRightRadius: '5px',
                                                borderTopLeftRadius: '3px',
                                                borderBottomLeftRadius: '3px',
                                                width: `${sell_ratio}px`,
                                                height: '20px', // Set your desired height
                                                backgroundColor: SELL_COLOR, // Set your desired background color
                                            }}
                                        />
                                        <Typography pl={2} sx={{
                                            fontFamily: 'monospace',
                                            color: SELL_COLOR,
                                        }}> {sell_ratio.toFixed(1) + '%'} </Typography>
                                    </Box>
                                </td>
                                <td>{Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                }).format(row.sell)}</td>
                                <td>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'right', // Adjust this to control spacing between boxes
                                        }}
                                    >
                                        <Typography sx={{
                                            color: BUY_COLOR
                                        }}> {row.num_of_buys} </Typography>
                                        -
                                        <Typography sx={{
                                            color: SELL_COLOR
                                        }}> {row.num_of_sells} </Typography>
                                        -
                                        <Typography>
                                            {row.num_of_trades}
                                        </Typography>
                                    </Box>
                                </td>
                            </tr>
                        );
                    })}
                {emptyRows > 0 && (
                    <tr
                        style={
                            {
                                height: `calc(${emptyRows} * 40px)`,
                                '--TableRow-hoverBackground': 'transparent',
                            } as React.CSSProperties
                        }
                    >
                        <td colSpan={6} aria-hidden/>
                    </tr>
                )}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={headCells.length + 1}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                justifyContent: 'flex-end',
                            }}
                        >
                            <FormControl orientation="horizontal" size="sm">
                                <FormLabel>Rows per page:</FormLabel>
                                <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                                    <Option value={5}>5</Option>
                                    <Option value={10}>10</Option>
                                    <Option value={25}>25</Option>
                                </Select>
                            </FormControl>
                            <Typography textAlign="center" sx={{minWidth: 80}}>
                                {labelDisplayedRows({
                                    from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
                                    to: getLabelDisplayedRowsTo(),
                                    count: rows.length === -1 ? -1 : rows.length,
                                })}
                            </Typography>
                            <Box sx={{display: 'flex', gap: 1}}>
                                <IconButton
                                    size="sm"
                                    color="neutral"
                                    variant="outlined"
                                    disabled={page === 0}
                                    onClick={() => handleChangePage(page - 1)}
                                    sx={{bgcolor: 'background.surface'}}
                                >
                                    <KeyboardArrowLeftIcon/>
                                </IconButton>
                                <IconButton
                                    size="sm"
                                    color="neutral"
                                    variant="outlined"
                                    disabled={
                                        rows.length !== -1
                                            ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                                            : false
                                    }
                                    onClick={() => handleChangePage(page + 1)}
                                    sx={{bgcolor: 'background.surface'}}
                                >
                                    <KeyboardArrowRightIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </td>
                </tr>
                </tfoot>
            </Table>
        </Sheet>
    );
}


export default function Cryptocurrencies() {
    return <TableSortAndSelection/>
}